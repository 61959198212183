var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "other-details detail-item-con"
  }, [_vm._t("header"), _c("OtherItem", {
    attrs: {
      data: _vm.detailData,
      "trees-old": _vm.detailData
    }
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };