/**
 * 元素折叠过度效果
 */
export default {
  name: 'CollapseTransition',
  props: {
    transitionName: {
      type: String,
      default: 'collapse-transition'
    }
  },
  data() {
    return {
      oldPaddingTop: '',
      oldPaddingBottom: '',
      oldOverflow: ''
    };
  },
  methods: {
    collapseBeforeEnter(el) {
      this.oldPaddingBottom = el.style.paddingBottom;
      this.oldPaddingTop = el.style.paddingTop;
      // 过渡效果开始前设置元素的maxHeight为0，让元素maxHeight有一个初始值
      el.style.paddingTop = '0';
      el.style.paddingBottom = '0';
      el.style.maxHeight = '0';
    },
    collapseEnter(el, done) {
      this.oldOverflow = el.style.overflow;
      const elHeight = el.scrollHeight;
      // 过渡效果进入后将元素的maxHeight设置为元素本身的高度，将元素maxHeight设置为auto不会有过渡效果
      if (elHeight > 0) {
        el.style.maxHeight = `${elHeight}px`;
      } else {
        el.style.maxHeight = '0';
      }
      el.style.paddingTop = this.oldPaddingTop;
      el.style.paddingBottom = this.oldPaddingBottom;
      el.style.overflow = 'hidden';
      const onTransitionDone = () => {
        done();
        el.removeEventListener('transitionend', onTransitionDone, false);
        el.removeEventListener('transitioncancel', onTransitionDone, false);
      };
      // 绑定元素的transition完成事件，在transition完成后立即完成vue的过度动效
      el.addEventListener('transitionend', onTransitionDone, false);
      el.addEventListener('transitioncancel', onTransitionDone, false);
    },
    collapseAfterEnter(el) {
      // 过渡效果完成后恢复元素的maxHeight
      el.style.maxHeight = '';
      el.style.overflow = this.oldOverflow;
    },
    collapseBeforeLeave(el) {
      this.oldPaddingBottom = el.style.paddingBottom;
      this.oldPaddingTop = el.style.paddingTop;
      this.oldOverflow = el.style.overflow;
      el.style.maxHeight = `${el.scrollHeight}px`;
      el.style.overflow = 'hidden';
    },
    collapseLeave(el, done) {
      if (el.scrollHeight !== 0) {
        el.style.maxHeight = '0';
        el.style.paddingBottom = '0';
        el.style.paddingTop = '0';
      }
      const onTransitionDone = () => {
        done();
        el.removeEventListener('transitionend', onTransitionDone, false);
        el.removeEventListener('transitioncancel', onTransitionDone, false);
      };
      // 绑定元素的transition完成事件，在transition完成后立即完成vue的过度动效
      el.addEventListener('transitionend', onTransitionDone, false);
      el.addEventListener('transitioncancel', onTransitionDone, false);
    },
    collapseAfterLeave(el) {
      el.style.maxHeight = '';
      el.style.overflow = this.oldOverflow;
      el.style.paddingBottom = this.oldPaddingBottom;
      el.style.paddingTop = this.oldPaddingTop;
      this.oldPaddingTop = '';
      this.oldPaddingBottom = '';
      this.oldOverflow = '';
    }
  }
};