var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("transition", {
    attrs: {
      name: _vm.transitionName
    },
    on: {
      "before-enter": _vm.collapseBeforeEnter,
      enter: _vm.collapseEnter,
      "after-enter": _vm.collapseAfterEnter,
      "before-leave": _vm.collapseBeforeLeave,
      leave: _vm.collapseLeave,
      "after-leave": _vm.collapseAfterLeave
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };