import SvgIcon from '@/components/SvgIcon';
import PayslipCard from './PayslipCard';
import OtherDetails from './OtherDetails';
export default {
  name: 'PersonalDetail',
  components: {
    SvgIcon,
    PayslipCard,
    OtherDetails
  },
  props: {
    basicData: {
      type: Object,
      default: () => {}
    },
    salaryData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      baseColor: '#008bff',
      otherColor: '#ff964a'
    };
  }
};