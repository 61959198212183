import OtherItem from './OtherItem';
export default {
  name: 'OtherDetails',
  components: {
    OtherItem
  },
  props: {
    detailData: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};