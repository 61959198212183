import { render, staticRenderFns } from "./DetailItem.vue?vue&type=template&id=94afb4ac&scoped=true"
import script from "./DetailItem.vue?vue&type=script&lang=js"
export * from "./DetailItem.vue?vue&type=script&lang=js"
import style0 from "./DetailItem.vue?vue&type=style&index=0&id=94afb4ac&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94afb4ac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/cci-14816-341419/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('94afb4ac')) {
      api.createRecord('94afb4ac', component.options)
    } else {
      api.reload('94afb4ac', component.options)
    }
    module.hot.accept("./DetailItem.vue?vue&type=template&id=94afb4ac&scoped=true", function () {
      api.rerender('94afb4ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/payslipDetail/DetailItem.vue"
export default component.exports