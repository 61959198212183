import DetailItem from './DetailItem';
export default {
  name: 'PayslipCard',
  components: {
    DetailItem
  },
  props: {
    detailData: {
      type: Object,
      required: true
    }
  }
};