/**
 * icon-font 图标组件
 * !iconfont更新时请修改根目录下public下的index.html 关于iconfont图标的引入
 */
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 16
    },
    color: {
      type: String,
      default: '#000'
    },
    realClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    dealPxToVw() {
      const vws = this.size / 3.75;
      return `${vws}vw`;
    }
  }
};