var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detail-item-con"
  }, [_vm._t("header"), _vm._l(_vm.detailData, function (value, key) {
    return _c("div", {
      key: key,
      staticClass: "box"
    }, [_c("DetailItem", {
      attrs: {
        title: key,
        content: value
      }
    })], 1);
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };