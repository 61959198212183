var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detail-list"
  }, [Object.keys(_vm.basicData).length > 0 ? _c("PayslipCard", {
    attrs: {
      "detail-data": _vm.basicData
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("h3", {
          staticClass: "title",
          style: {
            borderColor: _vm.baseColor
          }
        }, [_c("SvgIcon", {
          staticClass: "iconfont",
          attrs: {
            size: 20,
            "icon-class": "icon-gerenxinxi",
            color: _vm.baseColor
          }
        }), _c("span", [_vm._v("基本项目")])], 1)];
      },
      proxy: true
    }], null, false, 2238495151)
  }) : _vm._e(), _vm.salaryData.length > 0 ? _c("OtherDetails", {
    attrs: {
      "detail-data": _vm.salaryData
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("h3", {
          staticClass: "title",
          style: {
            borderColor: _vm.otherColor
          }
        }, [_c("SvgIcon", {
          staticClass: "iconfont",
          attrs: {
            size: 20,
            "icon-class": "icon-bili",
            color: _vm.otherColor
          }
        }), _c("span", [_vm._v("其他明细")])], 1)];
      },
      proxy: true
    }], null, false, 1909342205)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };