import "core-js/modules/es.array.push.js";
export const nameKey = '姓名';
export const mobileKey = '手机号';
export const paidWagesKey = '实发工资';

/**
 * 旧key到新key的映射
 * @param {Array|Object} data
 * @returns {Array|Object}
 */
const handleChangeFields = data => {
  return JSON.parse(JSON.stringify(data).replace(/name/g, nameKey).replace(/mobile/g, mobileKey).replace(/paidWages/g, paidWagesKey));
};

/**
 * 处理多级表头数据
 * @param {Array} data  表格数据
 * @returns {Array} 处理后的数据
 */
export const dealTableHeaderData = data => {
  let {
    detail = '[]'
  } = data[0];
  detail = detail.replace(/\\n/g, '');
  const tableData = JSON.parse(detail);
  const result = handleTHeaderData(tableData);
  return result;
};
const handleTHeaderData = arr => {
  const data = JSON.parse(JSON.stringify(arr));
  return transTreeKey(data);
};

/**
 * 数据转换
 * @param {Array} treeData 数据
 * @param {String} indexs 索引
 * @returns {Array} 转换后的数据
 */
let transTreeKey = (treeData, indexs = '') => {
  (treeData || []).forEach((item, index) => {
    item['indexs'] = indexs ? indexs + '_' + index : index + '';
    item.label = item.name;
    delete item.name;
    delete item.index;
    delete item.value;
    if (item.isGrossField) {
      transTreeKey(item.child, item['indexs']);
    }
  });
  return treeData;
};

/**
 * 处理表格数据
 * @param {Array} data 表格数据
 * @returns {Array} 处理后的数据
 */
export const dealTableData = data => {
  let result = [];
  (data || []).forEach(item => {
    let {
      detail = '[]'
    } = item;
    detail = detail.replace(/\\n/g, '');
    const tableData = JSON.parse(detail);
    const arrData = treeToArr(tableData);
    result.push(arrData);
  });
  return result;
};

/**
 * 树形结构转数组
 * @param {Array} data 树形结构数据
 * @param {String} indexs 索引
 * @param {Array} res 结果
 * @returns {*[]} 处理后的数组
 */
const treeToArr = (data, indexs = '', res = []) => {
  data.forEach((item, index) => {
    var _item$child;
    item['indexs'] = indexs ? indexs + '_' + index : index + '';
    item[item.name] = item.value;
    delete item.index;
    if (item.hasOwnProperty('value')) {
      res.push({
        indexs: item.indexs,
        value: !item.value ? '-' : item.value
      });
    }
    if (item !== null && item !== void 0 && (_item$child = item.child) !== null && _item$child !== void 0 && _item$child.length) {
      treeToArr(item.child, item['indexs'], res);
    }
  });
  return res;
};

/**
 * 处理详情数据
 * @param {Object} item 原始数据
 * @returns {Object} 处理后的数据
 */
const dealDetailData = item => {
  let {
    detail = '[]'
  } = item;
  detail = detail.replace(/\\n/g, '');
  let tableData = JSON.parse(detail);
  tableData = addExpandField(tableData);
  item.detail = tableData;
  return item;
};
const formatYearAndMonth = remainder => {
  const yearKey = '年度';
  const monthKey = '月份';
  const oldYearKey = 'year';
  const oldMonthKey = 'month';
  remainder[yearKey] = remainder[oldYearKey];
  delete remainder[oldYearKey];
  remainder[monthKey] = remainder[oldMonthKey];
  delete remainder[oldMonthKey];
  return remainder;
};

/**
 * 处理详情数据
 * @param payslipDetail 详情数据
 * @returns {{basicData: Array, salaryData: *[]}} 基本数据和其他数据
 */
export const formatPayslipDetail = payslipDetail => {
  const salaryData = dealDetailData(payslipDetail);
  let {
    detail = [],
    ...remainder
  } = salaryData;
  remainder = handleChangeFields(remainder);
  remainder = formatYearAndMonth(remainder);
  const basicData = remainder;
  let detailData = filterZeroData(detail) || [];
  detailData = deleteEmpty(detailData);
  return {
    basicData,
    detailData
  };
};

// 数据项为0或者空、undefined、null时，不显示该项
const filterZeroData = data => {
  let newData = data.filter(item => {
    var _item$child2;
    if ((item === null || item === void 0 || (_item$child2 = item.child) === null || _item$child2 === void 0 ? void 0 : _item$child2.length) > 0) {
      return item;
    } else {
      if (item.value && Number(item.value) !== 0) {
        return item;
      }
    }
  });
  newData.forEach(item => item.child && (item.child = filterZeroData(item.child)));
  return newData;
};

// 删除子集为空的父级
const deleteEmpty = list => {
  for (let idx = list.length - 1; idx >= 0; idx--) {
    const item = list[idx];
    if (!item.hasOwnProperty('child')) continue;
    if (item.child.length === 0) {
      list.splice(idx, 1);
      continue;
    }
    deleteEmpty(item.child);
  }
  return list;
};

/**
 * 添加展开字段
 * @param arr 数据
 * @param _level 层级
 * @returns {any} 处理后的数据
 */
const addExpandField = (arr, _level = 1) => {
  arr.forEach((item, index) => {
    item['_level'] = _level;
    if (item.child && item.child.length > 0) {
      // 默认全部展开
      item.expand = true;
      addExpandField(item.child, _level + 1);
    }
  });
  return JSON.parse(JSON.stringify(arr));
};
const recursionTree = (list, lastChildren) => {
  for (let item = 0; item < list.length; item++) {
    const chList = list[item];
    if (chList.isGrossField) {
      recursionTree(chList.child, lastChildren);
    } else {
      lastChildren.push(chList);
    }
  }
};

/**
 * 获取树形结构的最后一个子节点
 * @param {Array} json 树形结构数据
 * @returns {Object} 最后一个子节点
 */
export const getLastChild = json => {
  let lastChildren = [];
  recursionTree(json, lastChildren);
  return lastChildren[lastChildren.length - 1];
};

/**
 * 获取年月
 * @param {Number} yearMonth
 * @returns {{month: string, year: string}} 年月
 */
export const getYearMonth = yearMonth => {
  const yearMonthStr = yearMonth.toString();
  const year = yearMonthStr.substring(0, 4);
  const month = yearMonthStr.substring(4, 6);
  return {
    year,
    month
  };
};

/**
 * 处理表格列宽
 * @param key 列名
 * @param index 列索引
 * @returns {string} 列宽
 */
export const fitWidthFn = (key, index) => {
  let width = 'auto';
  if (index < 3) {
    width = '110';
  } else if (key.includes('部门')) {
    width = '200';
  } else if (key.length < 5) {
    width = 'auto';
  } else if (key.length === 5) {
    width = '110';
  } else if (key.length === 6) {
    width = '120';
  } else if (key.length === 7) {
    width = '150';
  } else if (key.length > 7) {
    width = '200';
  }
  return width;
};