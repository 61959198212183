export default {
  name: 'DetailItem',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: [String, Number],
      default: ''
    }
  }
};