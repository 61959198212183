var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detail-item"
  }, [_c("h3", [_vm._v(_vm._s(_vm.title))]), _c("i", {
    staticClass: "item-value"
  }, [_vm._v(_vm._s(_vm.content === "" ? "-" : _vm.content))])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };