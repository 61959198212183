var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "other-item-con"
  }, _vm._l(_vm.data, function (item, indexItem) {
    return _c("li", {
      key: indexItem
    }, [item.isGrossField ? [_c("div", {
      staticClass: "collapse-con"
    }, [_c("div", {
      staticClass: "content-box",
      on: {
        click: function ($event) {
          return _vm.toggleExpand(item);
        }
      }
    }, [_c("div", {
      staticClass: "title",
      style: {
        color: `rgba(0,0,0,${1 - item._level / 10 - 0.2})`
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("span", {
      class: {
        arrowTransform: item.expand,
        arrow: true,
        "arrow-visible": item.child && item.child.length
      }
    }, [_c("i", {
      staticClass: "el-icon-arrow-right"
    })])]), _c("CollapseTransition", [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.expand,
        expression: "item.expand"
      }]
    }, [_c("OtherItem", {
      attrs: {
        data: item.child,
        "trees-old": _vm.treesOld
      }
    })], 1)])], 1)] : [_c("div", {
      staticClass: "un-collapse-con",
      class: {
        "is-last": _vm.lastChild.index === item.index
      }
    }, [_c("DetailItem", {
      attrs: {
        title: item.name,
        content: item.value
      }
    })], 1)]], 2);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };