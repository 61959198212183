import { getLastChild } from '@/views/payslip/util/compileData';
import CollapseTransition from './CollapseTransition';
import DetailItem from './DetailItem';
export default {
  name: 'OtherItem',
  components: {
    CollapseTransition,
    DetailItem
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    treesOld: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      lastChild: {} // 最后一个子节点
    };
  },
  created() {
    this.lastChild = getLastChild(this.treesOld);
  },
  methods: {
    // 展开或收起
    toggleExpand(item) {
      this.$set(item, 'expand', !item.expand);
    }
  }
};