var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("svg", {
    class: ["svg-icon", _vm.realClass],
    style: {
      fontSize: _vm.dealPxToVw,
      color: _vm.color
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("use", {
    attrs: {
      "xlink:href": "#" + _vm.iconClass
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };